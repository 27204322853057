import {
  REGEX_VALID__POS_NEG_DECIMAL_NUMBERS,
  REGEX_VALID_DECIMAL_NUMBERS,
  REGEX_VALID_EMAIL,
  REGEX_VALID_NAME,
  REGEX_VALID_NUMBERS,
  REGEX_VALID_PHONE_NUMBER,
} from '@/app/common/constants/validations-config';
import { isEmpty } from '@/app/common/utils/filter';

const msgVerifyCharacter = 'Caracteres invalidos';

export const required = (v: string): string | boolean => !!v || 'Este campo es requerido';
export const requiredArray = (val: any[]): string | boolean =>
  (val && val.length > 0) || 'Este campo es requerido';
export const passwordValidator = (value: string): string | boolean => {
  let message = 'Formato no válido';
  const expReg =
    /^(?=.*[a-z\u00f1])(?=.*[A-Z\u00d1])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-\\“!@#%&/,><’:;|_~`])\S{8,60}$/;
  const capitalLetter = /[A-Z\u00d1]/;
  const especial = /[\^$*.[\]{}()?\-\\“!@#%&/,><’:;|_~`]/;
  const lowerCase = /[a-z\u00f1]/;
  const number = /[0-9]/;
  const array: string[] = [];
  if (!capitalLetter.test(value)) array.push('una mayúscula');
  if (!especial.test(value)) array.push('un caracter especial');
  if (!lowerCase.test(value)) array.push('una minúscula');
  if (!number.test(value)) array.push('un número');
  if (value.length <= 8) array.push('8 caracteres');
  if (array.length > 0) {
    message = 'Se requiere al menos (' + array.join(', ').toString() + ')';
  }
  return expReg.test(value) || message;
};
export const emailValidator = (v: string): string | boolean =>
  REGEX_VALID_EMAIL.test(v) || 'Email invalido';
export const namesValidator = (v: string) => REGEX_VALID_NAME.test(v) || msgVerifyCharacter;
export const phoneNumberValidator = (v: string) =>
  REGEX_VALID_PHONE_NUMBER.test(v) || 'Este campo debe tener el formato (+00)00000000';
export const numbersValidator = (v: string): string | boolean =>
  REGEX_VALID_NUMBERS.test(v) || 'Este campo acepta solo números';
export const decimalNumbersValidator = (v: string): string | boolean =>
  REGEX_VALID_DECIMAL_NUMBERS.test(v) || 'Este campo debe tener el formato 00.00';
export const posNegDecimalNumbersValidator = (v: string): string | boolean =>
  !REGEX_VALID__POS_NEG_DECIMAL_NUMBERS.test(v) || 'Este campo debe tener el formato -/+ 00.00';

export const lengthValidator = (value: any, length: number): string | boolean => {
  if (isEmpty(value)) {
    return true;
  }

  return value.length === length || `El campo debe tener ${length} caractares`;
};
