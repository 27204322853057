





























































































import { defineComponent, inject, ref } from '@vue/composition-api';
import { useUserStore } from '@/app/composables';
import { formatDateLocal, formatScript } from '@/app/common/utils/filter';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import { GLOBAL_NAME_ROLE } from '@/app/common/helpers/roles';
import ModalDeleteUser from '@/features/users/_components/modals/modal-delete-user/ModalDeleteUser.vue';
import {
  ChangePasswordModalRef,
  DeleteUserModalRef,
  LimitSpacesModalRef,
} from '@/app/models/modal';
import { UserModel } from '@/app/models/user';
import ModalLimitSpaces from '@/features/users/_components/modals/modal-limit-spaces/ModalLimitSpaces.vue';
import ModalChangePassword from '@/features/users/_components/modals/modal-change-password/ModalChangePassword.vue';

export default defineComponent({
  name: 'UsersTable',
  components: { ModalChangePassword, ModalLimitSpaces, ModalDeleteUser, CustomMessage },
  setup() {
    const globalRoleName = GLOBAL_NAME_ROLE;
    const { users, searchMode, isLoading, searchEnd } = useUserStore();
    const modalDeleteUser = ref<DeleteUserModalRef | null>(null);
    const modalLimitSpaces = ref<LimitSpacesModalRef | null>(null);
    const modalChangePassword = ref<ChangePasswordModalRef | null>(null);

    const openModalUserForm = inject<(user: UserModel | null) => void>('openModalUserForm');

    const openModalDeleteUser = (user: UserModel) => {
      if (!modalDeleteUser.value) return;
      modalDeleteUser.value.openDialog(user);
    };

    const openModalLimitSpaces = (user: UserModel) => {
      if (!modalLimitSpaces.value) return;
      modalLimitSpaces.value.openDialog(user);
    };

    const openModalChangePassword = (user: UserModel) => {
      if (!modalChangePassword.value) return;
      modalChangePassword.value.openDialog(user);
    };

    const getRoles = (roles: string[]): string => {
      if (roles.length === 0) {
        return 'Sin Roles';
      }

      const tempRoles = roles.map((x) => (GLOBAL_NAME_ROLE as any)[x] || 'Rol');
      return tempRoles.join(', ');
    };

    return {
      users,
      searchMode,
      isLoading,
      globalRoleName,

      searchEnd,
      openModalDeleteUser,
      openModalLimitSpaces,
      openModalChangePassword,
      getRoles,

      openModalUserForm,

      filters: { formatDateLocal, formatScript },

      modalDeleteUser,
      modalLimitSpaces,
      modalChangePassword,
    };
  },
});
