import useStore from '@/app/common/hooks/useStore';
import { computed } from '@vue/composition-api';
import { Pagination } from '@/app/models/paginate';
import { ErrorHandler } from '@/app/models/error';
import { SpaceModel } from '@/app/models/space';

export const useSpaceStore = () => {
  const store = useStore();
  return {
    // Getters
    isLoading: computed<boolean>(() => store.getters['space/isLoading']),
    filterMode: computed<boolean>(() => store.getters['space/filterMode']),
    searchMode: computed<boolean>(() => store.getters['space/searchMode']),
    query: computed<string>(() => store.getters['space/query']),
    pagination: computed<Pagination | null>(() => store.getters['space/pagination']),
    spaces: computed<SpaceModel[]>(() => store.getters['space/spaces']),
    error: computed<ErrorHandler>(() => store.getters['space/error']),

    // Mutations
    resetStatus: () => store.commit('space/resetStatus'),
    setPaginate: (val: number) => store.commit('space/setPaginate', val),
    setFilterMode: (val: boolean) => store.commit('space/setFilterMode', val),
    setSearchMode: (val: boolean) => store.commit('space/setSearchMode', val),
    setQuery: (query: string) => store.commit('space/setQuery', query),
    setPaginateQuery: (query: string | null) => store.commit('space/setPaginateQuery', query),

    // Actions
    loadSpaces: () => store.dispatch('space/loadSpaces'),
    searchEnd: () => store.dispatch('space/searchEnd'),
  };
};
