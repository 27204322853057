import useStore from '@/app/common/hooks/useStore';
import { computed } from '@vue/composition-api';
import { Pagination } from '@/app/models/paginate';
import { UserFormValues, UserModel } from '@/app/models/user';
import { ErrorHandler } from '@/app/models/error';

export const useUserStore = () => {
  const store = useStore();
  return {
    // Getters
    isLoading: computed<boolean>(() => store.getters['user/isLoading']),
    filterMode: computed<boolean>(() => store.getters['user/filterMode']),
    searchMode: computed<boolean>(() => store.getters['user/searchMode']),
    query: computed<string>(() => store.getters['user/query']),
    pagination: computed<Pagination | null>(() => store.getters['user/pagination']),
    users: computed<UserModel[]>(() => store.getters['user/users']),
    error: computed<ErrorHandler>(() => store.getters['user/error']),

    // Mutations
    resetStatus: () => store.commit('user/resetStatus'),
    setPaginate: (val: number) => store.commit('user/setPaginate', val),
    setFilterMode: (val: boolean) => store.commit('user/setFilterMode', val),
    setSearchMode: (val: boolean) => store.commit('user/setSearchMode', val),
    setQuery: (query: string) => store.commit('user/setQuery', query),
    setPaginateQuery: (query: string | null) => store.commit('user/setPaginateQuery', query),

    // Actions
    loadUsers: () => store.dispatch('user/loadUsers'),
    addUser: (user: UserFormValues) => store.dispatch('user/addUser', user),
    editUser: (user: UserFormValues) => store.dispatch('user/editUser', user),
    deleteUser: (user: UserModel) => store.dispatch('user/deleteUser', user),
    /*editCompany: (user: UserFormValues) => store.dispatch('user/editUser', user),*/
    searchEnd: () => store.dispatch('user/searchEnd'),
  };
};
