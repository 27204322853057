import { getCurrentInstance, reactive, toRef, watch } from '@vue/composition-api';

const useToastification = () => {
  const vm = getCurrentInstance()!.proxy;

  const state = reactive({
    toastification: vm.$toast,
  });

  watch(
    () => vm.$toast,
    r => {
      state.toastification = r;
    },
  );

  return toRef(state, 'toastification').value;
};
export default useToastification;
