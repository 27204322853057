import {
  REGEX_VALID__POS_NEG_DECIMAL_NUMBERS,
  REGEX_VALID_DECIMAL_NUMBERS,
  REGEX_VALID_NUMBERS,
} from '@/app/common/constants/validations-config';

export const validDecimalNumbers = (event: KeyboardEvent): boolean => {
  if (!REGEX_VALID_DECIMAL_NUMBERS.test(event.key)) {
    event.preventDefault();
    return false;
  }
  return true;
};
export const validPosNegDecimalNumbers = (event: KeyboardEvent): boolean => {
  if (!REGEX_VALID__POS_NEG_DECIMAL_NUMBERS.test(event.key)) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const validNumbers = (event: KeyboardEvent): boolean => {
  if (!REGEX_VALID_NUMBERS.test(event.key)) {
    event.preventDefault();
    return false;
  }
  return true;
};

export const convertTrueFalse = (data: string | boolean | any): boolean => {
  return data === 'true';
};
