export interface User {
  accessToken: string;
  user: string;
  role: string;
}

export interface UserModel {
  _id: string;
  email: string;
  name: string;
  lastName: string;
  role: string;
  roles: string[];
  created: string;
  sub: string;
  phone: string;
  iid: string;
}

export class UserFormValues {
  _id?: string = undefined;
  role = '';
  roles: string[] = [];
  name = '';
  lastName = '';
  email = '';
  phone = '';
  constructor(user?: UserFormValues) {
    Object.assign(this, user);
  }
}
