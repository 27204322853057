export const ROLE_ROOT = 'ROOT';
export const ROLE_USER = 'USER';
export const ROLE_MONITOR = 'MONITOR';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_PROGRAMMER = 'PROGRAMMER';

export const GLOBAL_NAME_ROLE = {
  [ROLE_ROOT]: 'Admin',
  [ROLE_ADMIN]: 'Administrador',
  [ROLE_USER]: 'Usuario',
  [ROLE_MONITOR]: 'Monitor',
  [ROLE_PROGRAMMER]: 'Programador',
};
