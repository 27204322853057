





















import { defineComponent, inject } from '@vue/composition-api';
import SearchUser from '@/features/users/_components/search-user/SearchUser.vue';
import { UserModel } from '@/app/models/user';

export default defineComponent({
  name: 'UsersHeader',
  components: { SearchUser },
  setup() {
    const openModalUserForm = inject<(user: UserModel | null) => void>('openModalUserForm');
    return { openModalUserForm };
  },
});
