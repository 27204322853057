



















import { defineComponent } from '@vue/composition-api';
import { useUserStore } from '@/app/composables';

export default defineComponent({
  name: 'UsersPaginate',
  setup() {
    const { pagination, isLoading, setPaginate, loadUsers } = useUserStore();
    const handlePagination = (val: number): void => {
      setPaginate(val);
      loadUsers().then();
    };
    return { pagination, isLoading, handlePagination };
  },
});
