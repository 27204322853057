



















import { defineComponent, ref } from '@vue/composition-api';
import { UserModel } from '@/app/models/user';
import { useUserStore } from '@/app/composables';

export default defineComponent({
  name: 'ModalDeleteUser',
  setup() {
    const { deleteUser } = useUserStore();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const currentUser = ref<UserModel | null>(null);

    const openDialog = (user: UserModel) => {
      currentUser.value = user;
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      isDialogVisible.value = false;
    };

    const handleDeleteUser = () => {
      if (!currentUser.value) return;
      isLoading.value = true;
      deleteUser(currentUser.value).finally(() => {
        isLoading.value = false;
      });
    };

    return { isDialogVisible, isLoading, currentUser, handleDeleteUser, closeDialog, openDialog };
  },
});
