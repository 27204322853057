import useStore from '@/app/common/hooks/useStore';
import { ErrorModel } from '@/app/models/error';

const useApiGatewayInterceptor = () => {
  const store = useStore();

  return {
    handleApiGatewayInterceptor: (response: ErrorModel) =>
      store.dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', response),
  };
};
export default useApiGatewayInterceptor;
