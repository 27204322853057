import useStore from '@/app/common/hooks/useStore';
import { computed } from '@vue/composition-api';
import { Pagination } from '@/app/models/paginate';
import { CompanyFormValues, CompanyModel } from '@/app/models/company';
import { ErrorHandler } from '@/app/models/error';

export const useCompanyStore = () => {
  const store = useStore();
  return {
    // Getters
    isLoading: computed<boolean>(() => store.getters['company/isLoading']),
    filterMode: computed<boolean>(() => store.getters['company/filterMode']),
    searchMode: computed<boolean>(() => store.getters['company/searchMode']),
    query: computed<string>(() => store.getters['company/query']),
    pagination: computed<Pagination | null>(() => store.getters['company/pagination']),
    companies: computed<CompanyModel[]>(() => store.getters['company/companies']),
    error: computed<ErrorHandler>(() => store.getters['company/error']),

    // Mutations
    resetStatus: () => store.commit('company/resetStatus'),
    setPaginate: (val: number) => store.commit('company/setPaginate', val),
    setFilterMode: (val: boolean) => store.commit('company/setFilterMode', val),
    setSearchMode: (val: boolean) => store.commit('company/setSearchMode', val),
    setQuery: (query: string) => store.commit('company/setQuery', query),
    setPaginateQuery: (query: string | null) => store.commit('company/setPaginateQuery', query),

    // Actions
    loadCompanies: () => store.dispatch('company/loadCompanies'),
    addCompany: (company: CompanyFormValues) => store.dispatch('company/addCompany', company),
    editCompany: (company: CompanyFormValues) => store.dispatch('company/editCompany', company),
    searchEnd: () => store.dispatch('company/searchEnd'),
  };
};
