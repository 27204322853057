var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.users.length > 0)?_c('v-simple-table',{attrs:{"fixed-header":"","height":"450px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Nombres y Apellidos")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Fecha Creación")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Correo")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Roles")]),_c('th',{staticClass:"text-center primary--text",attrs:{"scope":"col"}},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.users),function(tempUser,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.filters.formatScript(tempUser.name, true) + ' ' + _vm.filters.formatScript(tempUser.lastName, true))+" ")]),_c('td',{staticClass:"text-center text-no-wrap"},[_vm._v(" "+_vm._s(_vm.filters.formatDateLocal(tempUser.created))+" ")]),_c('td',{staticClass:"text-center text-no-wrap"},[_vm._v(" "+_vm._s(_vm.filters.formatScript(tempUser.email))+" ")]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"200px"}},[_vm._v(" "+_vm._s(_vm.getRoles(tempUser.roles))+" ")]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"success"},on:{"click":function () { return _vm.openModalChangePassword(tempUser); }}},[_c('v-icon',[_vm._v("mdi-key-chain")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Cambiar Contraseña")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"info"},on:{"click":function () { return _vm.openModalUserForm(tempUser); }}},[_c('v-icon',[_vm._v("mdi-clipboard-edit-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Editar Usuario")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function () { return _vm.openModalLimitSpaces(tempUser); }}},[_c('v-icon',[_vm._v("mdi-calendar-text-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Limitar Inmuebles")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","color":"pink"},on:{"click":function () { return _vm.openModalDeleteUser(tempUser); }}},[_c('v-icon',[_vm._v("mdi-delete-empty-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)]},proxy:true}],null,false,869033819)}):_vm._e(),(_vm.searchMode && !_vm.isLoading && _vm.users.length === 0)?_c('custom-message',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"height":"55vh"},attrs:{"message":"No se encontraron resultados, para la busqueda"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.searchEnd}},[_vm._v("Aceptar")])],1):_vm._e(),_c('modal-delete-user',{ref:"modalDeleteUser"}),_c('modal-limit-spaces',{ref:"modalLimitSpaces"}),_c('modal-change-password',{ref:"modalChangePassword"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }