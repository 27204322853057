























































import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { passwordValidator, required } from '@/app/common/validator';
import { RefFormModel } from '@/app/models/reference';
import { UserModel } from '@/app/models/user';
import usersServices from '@/app/services/users.services';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import { ErrorModel } from '@/app/models/error';
import useToastification from "@/app/common/hooks/useToastification";

export default defineComponent({
  name: 'ModalChangePassword',
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const $toast = useToastification();
    const isLoading = ref<boolean>(false);
    const isDialogVisible = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const currentUser = ref<null | UserModel>(null);
    const changePasswordForm = ref<null | RefFormModel>(null);
    const showPassword = ref<boolean>(false);
    const showConfirmPassword = ref<boolean>(false);
    const form = reactive<{ password: string; newPassword: string }>({
      newPassword: '',
      password: '',
    });

    const passwordMatch = computed<boolean | string>(() => {
      return form.password === form.newPassword || 'Las contraseñas no coinciden';
    });

    const openDialog = (user: UserModel) => {
      isDialogVisible.value = true;
      currentUser.value = user;
    };

    const closeDialog = () => {
      isDialogVisible.value = false;
      form.newPassword = '';
      form.password = '';
      changePasswordForm.value!.resetValidation();
    };

    const changePassword = async () => {
      try {
        if (!currentUser.value) return;
        if (!changePasswordForm.value!.validate()) return;
        isLoading.value = true;
        await usersServices.changePassword(currentUser.value._id, form);
        closeDialog();
        $toast.success('Contraseña cambiada correctamente');
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isDialogVisible,
      form,
      isLoading,
      valid,
      showPassword,
      showConfirmPassword,

      changePassword,
      openDialog,
      closeDialog,

      passwordMatch,

      validators: {
        required,
        passwordValidator,
      },

      changePasswordForm,
    };
  },
});
