import { defineComponent, reactive, ref } from '@vue/composition-api';
import { RefFormModel } from '@/app/models/reference';
import {
  required,
  requiredArray,
  numbersValidator,
  emailValidator,
  lengthValidator,
} from '@/app/common/validator';
import { validNumbers } from '@/app/common/utils/check-validators';
import { useUserStore } from '@/app/composables';
import { UserFormValues, UserModel } from '@/app/models/user';
import { SelectModel } from '@/app/models/select-model';

export default defineComponent({
  name: 'ModalUserForm',
  setup() {
    const { addUser, editUser, loadUsers, setPaginate } = useUserStore();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const editMode = ref<boolean>(false);
    const roles = ref<string[]>([]);
    const userForm = ref<RefFormModel | null>(null);
    const rolesItems = ref<SelectModel<string>[]>([
      { value: 'USER', text: 'Usuario' },
      { value: 'MONITOR', text: 'Monitor' },
      { value: 'PROGRAMMER', text: 'Programador' },
    ]);

    const form = reactive<UserFormValues>(new UserFormValues());

    const openDialog = (user: UserModel | null) => {
      if (user) {
        editMode.value = true;
        const userForm: UserFormValues = {
          ...form,
          ...user,
        };
        userForm.phone = userForm.phone.slice(3, userForm.phone.length);
        Object.assign(form, new UserFormValues(userForm));
      }
      isDialogVisible.value = true;
    };

    const closeDialog = () => {
      if (!userForm.value) return;
      isDialogVisible.value = false;
      Object.assign(form, new UserFormValues());
      userForm.value.resetValidation();
      editMode.value = false;
    };

    const handleFormSubmit = () => {
      const isFormValid = userForm.value!.validate();
      if (!isFormValid) return;
      isLoading.value = true;
      const formData = new UserFormValues(form);
      /*  console.log(formData);*/
      formData.phone = '+51' + formData.phone;
      if (editMode.value) {
        editUser(formData)
          .then(() => {
            loadUsers().then();
            closeDialog();
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
      if (!editMode.value) {
        addUser(formData)
          .then(() => {
            setPaginate(1);
            loadUsers().then();
            closeDialog();
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    const remove = (item: SelectModel<string>) => {
      console.log(form.roles);
      const index = form.roles.indexOf(item.value);
      if (index >= 0) form.roles.splice(index, 1);
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      editMode,
      form,
      rolesItems,
      roles,

      handleFormSubmit,
      openDialog,
      closeDialog,
      remove,

      validators: {
        required,
        requiredArray,
        numbersValidator,
        emailValidator,
        lengthValidator,
      },

      checkValidators: {
        validNumbers,
      },

      userForm,
    };
  },
});
