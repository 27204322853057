export const formatDate = (date: string | null, empty: boolean) => {
  // salida dd/mm/aaaa
  if (!date) return empty ? '' : '-';
  const format = new Date(date);
  const time = `${format.toISOString().substr(0, 10)}`;
  const [year, month, day] = time.split('-');
  return `${day}/${month}/${year}`;
};

export const formatDateLocal = (date: string | null, showHour = true): string => {
  if (!date) return '-';
  const format = new Date(date);
  const zone = format.getTimezoneOffset();
  format.setHours(format.getHours() - zone / 60);
  let day: string | null = '',
    hour: string | null = '';
  hour = format24Hours(format.toISOString().substr(11, 5));
  day = formatDay(format.toISOString().substr(0, 10));
  return showHour ? `${day} ${hour}` : `${day}`;
};

const formatDay = (date: string): string | null => {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
};

const format24Hours = (date: string, showSeg = false, delimit = ':'): string | null => {
  if (!date) return null;
  const [hour, min, seg] = date.split(delimit);
  let H: string | number = Number.parseInt(hour);
  H = H < 10 ? '0' + H : H;
  return `${H}:${min}${showSeg ? ':' + seg : ''}`;
};

export const formatScript = (data: any, empty = false): any => {
  return data ? data : empty ? '' : '-';
};
export const formatTruFalse = (data = false): any => {
  return data ? 'Si' : 'No';
};

export const formatNull = (data: any): any => {
  return data ? data : null;
};

export const isEmpty = (value: any): boolean => {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return false;
};
