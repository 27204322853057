import { getCurrentInstance, reactive, toRef, watch } from '@vue/composition-api';

const useStore = () => {
  const vm = getCurrentInstance()!.proxy;

  const state = reactive({
    store: vm.$store,
  });

  watch(
    () => vm.$store,
    r => {
      state.store = r;
    },
  );

  return toRef(state, 'store').value;
};
export default useStore;
