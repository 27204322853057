




















































import { defineComponent, reactive, ref } from '@vue/composition-api';
import useApiGatewayInterceptor from '@/app/composables/useApiGatewayInterceptor';
import { ErrorModel } from '@/app/models/error';
import countsServices from '@/app/services/counts.services';
import { UserModel } from '@/app/models/user';
import { numbersValidator, required } from '@/app/common/validator';
import { validNumbers } from '@/app/common/utils/check-validators';
import { RefFormModel } from '@/app/models/reference';
import usersServices from '@/app/services/users.services';
import useToastification from '@/app/common/hooks/useToastification';

export default defineComponent({
  name: 'ModalLimitSpaces',
  setup() {
    const { handleApiGatewayInterceptor } = useApiGatewayInterceptor();
    const $toast = useToastification();
    const isDialogVisible = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const valid = ref<boolean>(true);
    const loadingCurrentSpaces = ref<boolean>(true);

    const form = reactive<{
      userId: string | null;
      currentSpaces: number | null;
      maxSpaceLimit: number | null;
    }>({
      userId: null,
      currentSpaces: null,
      maxSpaceLimit: null,
    });

    const limitSpacesForm = ref<RefFormModel | null>(null);

    const getLimitSpace = async (userId: string) => {
      try {
        loadingCurrentSpaces.value = true;
        const response = await countsServices.contSpace(userId);
        form.currentSpaces = response.limit;
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        loadingCurrentSpaces.value = false;
      }
    };

    const updateLimit = async () => {
      try {
        const isFormValid = limitSpacesForm.value!.validate();
        if (!isFormValid) return;
        isLoading.value = true;
        await usersServices.maxSpaceLimit(form);
        $toast.success('El maximo de inmuebles se actualizo correctamente');
        closeDialog();
      } catch (err) {
        await handleApiGatewayInterceptor(err.response as ErrorModel);
      } finally {
        isLoading.value = false;
      }
    };

    const openDialog = (user: UserModel) => {
      getLimitSpace(user._id).then();
      isDialogVisible.value = true;
      form.userId = user._id;
    };

    const closeDialog = () => {
      if (!limitSpacesForm.value) return;
      isDialogVisible.value = false;
      form.currentSpaces = null;
      form.maxSpaceLimit = null;
      limitSpacesForm.value.resetValidation();
    };

    return {
      isDialogVisible,
      isLoading,
      valid,
      loadingCurrentSpaces,
      form,

      openDialog,
      closeDialog,
      updateLimit,

      validators: {
        required,
        numbersValidator,
      },

      checkValidators: {
        validNumbers,
      },

      limitSpacesForm,
    };
  },
});
