

























import { defineComponent, onMounted } from '@vue/composition-api';
import CustomMainTable from '@/app/common/components/custom-main-table/CustomMainTable.vue';
import CustomProgressCircular from '@/app/common/components/custom-progress-circular/CustomProgressCircular.vue';
import CustomMessage from '@/app/common/components/custom-message/CustomMessage.vue';
import { useUserStore } from '@/app/composables';
import UsersTable from '@/features/users/users-table/UsersTable.vue';

export default defineComponent({
  name: 'UsersList',
  components: { UsersTable, CustomMessage, CustomProgressCircular, CustomMainTable },
  setup() {
    const { isLoading, error, loadUsers, searchEnd } = useUserStore();
    onMounted(() => {
      loadUsers();
    });
    return {
      isLoading,
      error,
      searchEnd,
    };
  },
});
