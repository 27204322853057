









import { defineComponent, onUnmounted, provide, ref } from '@vue/composition-api';
import UsersHeader from '@/features/users/users-header/UsersHeader.vue';
import UsersList from '@/features/users/users-list/UsersList.vue';
import UsersPaginate from '@/features/users/users-paginate/UsersPaginate.vue';
import ModalUserForm from '@/features/users/_components/modals/modal-user-form/ModalUserForm.vue';
import { useUserStore } from '@/app/composables';
import { UserFormModalRef } from '@/app/models/modal';
import {UserModel} from "@/app/models/user";

export default defineComponent({
  name: 'UsersView',
  components: { ModalUserForm, UsersPaginate, UsersList, UsersHeader },
  setup() {
    const { resetStatus } = useUserStore();
    const modalUserForm = ref<UserFormModalRef | null>(null);

    onUnmounted(() => {
      resetStatus();
    });

    const openModalUserForm = (user: UserModel | null) => {
      if (!modalUserForm.value) return;
      modalUserForm.value.openDialog(user);
    };

    provide<(user: UserModel | null) => void>('openModalUserForm', openModalUserForm);

    return { openModalUserForm, modalUserForm };
  },
});
