import request from '../api/api';

const countsServices = {
  contSpace: (userId: string): Promise<{ limit: number }> =>
    request.get<{ limit: number }>(`/counts/contSpace`, { userId }),
  contUser: (spaceId: string): Promise<{ limit: number }> =>
    request.get<{ limit: number }>(`/counts/contUser`, { spaceId }),
};

export default countsServices;
