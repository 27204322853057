





















import { computed, defineComponent } from '@vue/composition-api';
import { useUserStore } from '@/app/composables';

export default defineComponent({
  name: 'SearchUser',
  setup() {
    const { query, isLoading, searchEnd, setQuery, setPaginateQuery, setPaginate, loadUsers } =
      useUserStore();
    const userQuery = computed({
      get(): string {
        return query.value;
      },
      set(val: string): void {
        setQuery(val);
      },
    });

    const handleSearch = (): void => {
      setPaginate(1);
      /*if (userQuery.value && userQuery.value !== '') {*/
      setPaginateQuery(userQuery.value);
      loadUsers().then();
      /* }*/
    };

    return { userQuery, isLoading, searchEnd, handleSearch };
  },
});
